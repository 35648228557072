/* You can add global styles to this file, and also import other style files */
body {
  font-family: 'Poppins', sans-serif;
  background-color: #ededed;
  overflow-x: hidden;
}

h4 {
  font-size: 20px;
  font-weight: 500;
}

/* Common Style */
.opacity-10 {
  opacity: 0.1;
}

.opacity-20 {
  opacity: 0.2;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-70 {
  opacity: 0.7;
}

.opacity-80 {
  opacity: 0.8;
}

.opacity-90 {
  opacity: 0.9;
}

.sunset-yellow {
  color: #fad702;
}

.w-20 {
  width: 20%;
}

.sunset-yellow-bg {
  background-color: #fad702;
}

.sunset-F3F5F6-bg {
  background-color: #f3f5f6;
}

.sunset-F2F2F2-bg {
  background-color: #f2f2f2;
}

.sunset-ffffff-bg {
  background-color: #fff;
}

.font-FBBC04 {
  color: #fbbc04;
}

.sunset-00A14B-bg {
  background-color: #00a14b;
}

.sunset-C4977C-bg {
  background-color: #c4977c;
}

.sunset-6CE3A4-bg {
  background-color: #6ce3a4;
}

.sunset-39E38833-bg {
  background: #39e38833;
}

.sunset-E5E5E5-bg {
  background: #e5e5e5;
}

.sunset-FFEE50-bg {
  background: #ffee50;
}

.sunset-FFEE50-bg {
  background: #ffee50;
}

.sunset-D8D8D8-bg {
  background-color: #d8d8d8;
}

.sunset-C5997C-bg {
  background-color: #c5997c;
}

.sunset-34A853-bg {
  background-color: #34a853;
}

.sunset-F89A34-bg {
  background-color: #f89a34;
}

.sunset-000000-bg {
  background-color: #000000;
}

.sunset-193B3C-bg {
  background-color: #193b3c;
}

.border-radidus-4 {
  border-radius: 4px;
}

.border-radidus-8 {
  border-radius: 8px;
}

.border-radidus-10 {
  border-radius: 10px;
}

.border-radidus-12 {
  border-radius: 12px;
}

.border-radidus-16 {
  border-radius: 16px;
}

.border-radidus-24 {
  border-radius: 24px;
}

.border-radidus-50 {
  border-radius: 50px;
}

.font-w-400 {
  font-weight: 400;
}

.font-w-500 {
  font-weight: 500;
}

.font-w-600 {
  font-weight: 600;
}

.font-w-700 {
  font-weight: 700;
}

.font-18 {
  font-size: 18px;
}

.font-15 {
  font-size: 15px;
}

.font-16 {
  font-size: 16px;
}

.font-36 {
  font-size: 36px;
}

.font-42 {
  font-size: 42px;
}

.font-38 {
  font-size: 38px;
}

.font-48 {
  font-size: 48px;
}

.font-30 {
  font-size: 30px !important;
}

.font-24 {
  font-size: 24px;
}

.font-20 {
  font-size: 20px;
}

.font-14 {
  font-size: 14px;
}

.font-12 {
  font-size: 12px;
}

.font-10 {
  font-size: 10px;
}

.font-8 {
  font-size: 8px;
}

.font-6E757C {
  color: #6e757c;
}

.font-DC4437 {
  color: #dc4437;
}

.font-272E35 {
  color: #272e35;
}

.font-919191 {
  color: #919191;
}

.font-848F9F {
  color: #848f9f;
}

.font-656565 {
  color: #656565;
}

.font-909090 {
  color: #909090;
}

.font-00A14B {
  color: #00a14b;
}

.font-C5997C {
  color: #c5997c;
}

.font-34A853 {
  color: #34a853;
}

.font-5F738C {
  color: #5f738c;
}

.font-ffffff,
.font-ffffff:hover {
  color: #fff;
  text-decoration: none;
}

.font-292930 {
  color: #292930;
}

.font-999FAE {
  color: #999fae;
}

.font-000000 {
  color: #000000;
}

.font-bg-ffc107 {
  background-color: #ffc107;
}

.text-green {
  color: #c5e41a;
}

.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

.w-30 {
  width: 30%;
}

.w-45 {
  width: 45%;
}

.h-45 {
  height: 45%;
}

.w-fit-content {
  width: fit-content;
}

.border-left-2-C4C4C4 {
  border-left: 2px solid #c4c4c4;
}

.border-right-2-C4C4C4 {
  border-right: 2px solid #c4c4c4;
}

.sunset-input {
  border: 1px solid #e3e6e9;
}

.border-2-00A14B {
  border: 2px solid #00a14b;
}

.border-2-909090 {
  border: 2px solid #909090;
}

.pointer {
  cursor: pointer;
}

/* Common Style */
.btn:active,
.btn:focus {
  box-shadow: none;
  outline: none;
}

.btn:disabled {
  color: #FFFFFF;
  background-color: #193B3C;
  border: 2px solid #193B3C;
  opacity: unset
}

.btn {
  min-width: 180px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  padding: 12px 25px;
}

/* .btn-primary:hover, .navbar-nav .btn-primary.nav-link.show { background-color: transparent; color: #193B3C; border: 2px solid #193B3C; } */
.btn-primary {
  color: #FFFFFF;
  background-color: #193B3C;
  border: 2px solid #193B3C;
}

.btn-primary:hover,
.btn.btn-primary:active {
  background-color: transparent;
  color: #193B3C;
  border: 2px solid #193B3C;
}

.btn-submit {
  color: #FFFFFF;
  background-color: #193B3C;
  border: 2px solid #193B3C;
}

.btn-submit:hover,
.btn.btn-submit:active {
  background-color: transparent;
  color: #193B3C;
  border: 2px solid #193B3C;
}

.btn-secondary {
  color: #FFFFFF;
  background-color: #C3997D;
  border: 2px solid #C3997D;
}

.btn-secondary:hover,
.btn.btn-secondary:active {
  background-color: transparent;
  color: #C3997D;
  border: 2px solid #C3997D;
}

.btn-group-style {
  display: flex;
  justify-content: space-between;
  padding: 30px 0px;
}

.btn-yellow {
  color: #FFFFFF;
  background-color: #fad702;
  border: 2px solid #fad702;
}

.btn-yellow:hover,
.btn.btn-yellow:active {
  background-color: transparent;
  color: #fad702;
  border: 2px solid #fad702;
}

.Loader2 {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.withdraw-btn {
  min-width: 180px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  padding: 12px 25px;
  color: #FFFFFF;
  background-color: #193B3C;
  border: 2px solid #193B3C;
  border-radius: 6px;
}

.content-box-style {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #193B3C;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}

.icon-box {
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.icon-box img {
  height: 50px;
  width: 50px;
}

.content-box-style:hover {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
  background-color: #C3997D;
}

.content-box-style a:before {
  height: 100%;
  width: 100%;
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.navshadow-style {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 99;
}

.bg-darkblue {
  background-color: #193B3C;
}

.radius-10 {
  border-radius: 10px;
}

.dropdown-menu[data-bs-popper] {
  position: absolute;
  right: 0px;
  left: initial;
}

.nav-link.dropdown-toggle {
  background-color: #186300;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  padding-right: 10px;
}

.nav-link.dropdown-toggle img {
  height: 20px;
  width: 20px;
}

.nav-link.dropdown-toggle:hover {
  background-color: #ffc107;
  color: #000000;
}

.nav-link.active {
  color: #495057 !important;
}

/* Menu & Sidebar Style */
ul.nav-style li {
  border-bottom: 1px solid #F1f1f1;
  transition: all 0.3s ease-in-out;
  position: relative;
}

ul.nav-style li a {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  background-color: transparent;
  padding: 15px 15px;
  transition: all 0.3s ease-in-out;
  text-align: center;
}

/* ul.nav-style li:hover a, ul.nav-style li.active a { background-color: #193B3C; color: #FFFFFF; } */
ul.nav-style li:hover:before,
ul.nav-style li.active:before {
  width: 6px;
  height: 100%;
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: #00a14b;
  transition: all 0.3s ease-in-out;
}

ul.nav-style li a .menu-icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background-color: #FFFFFF;
  border-radius: 100%;
  padding: 5px;
  transition: all 0.3s ease-in-out;
}

ul.nav-style li a .menu-icon-box svg path {
  stroke: #000000;
}

ul.nav-style li a .menu-icon-box svg.svg-style {
  fill: #000000;
}

ul.nav-style li:hover a .menu-icon-box svg.svg-style path,
ul.nav-style li.active a .menu-icon-box svg.svg-style path {
  fill: #00a14b;
}

ul.nav-style li:hover a .menu-icon-box svg path,
ul.nav-style li.active a .menu-icon-box svg path {
  stroke: #00a14b;
}

ul.nav-style li:hover a,
ul.nav-style li.active a {
  color: #00a14b !important;
}

ul.nav-style li a .menu-icon-box img {
  height: 25px;
  width: 25px;
}

ul.nav-style li:hover a .menu-icon-box,
ul.nav-style li.active a .menu-icon-box {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

/* Body Style */
.page-content.no-sidebar {
  display: inherit;
}

.bg-style {
  min-height: calc(100vh - 80px);
  background-image: url(./assets/images/energia_image15.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-style:before {
  height: 100%;
  width: 100%;
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.3);
}

.page-content {
  display: flex;
  flex-grow: 1;
}

.page-content .sidebar-content {
  width: 180px;
  max-height: 88%;
  position: fixed;
  left: 0px;
  top: 80px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 5;
}

.page-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
}

.page-title h4 {
  font-size: 24px;
  font-weight: 600;
}

.page-content .content-wrapper {
  width: 100%;
  margin-left: 180px;
}

.page-content .content-wrapper .content {
  min-height: calc(100vh - 215px);
  max-height: 100%;
}

.page-content.no-sidebar .footer {
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
}

/* Table Style */
.table-box .table-responsive {
  width: 100%;
  overflow-x: auto;
}

.table-box table tr th {
  font-weight: 600;
  background-color: #ededed;
  color: #193B3C;
}

.table-box table tr th,
.table-box table tr td {
  vertical-align: middle;
  padding: 15px 30px;
}

.table-box table tr:hover td {
  background-color: rgba(25, 59, 60, 0.01);
}

/* Switch Style */
.switches {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
}

.switches h3 {
  font-size: 20px;
  font-weight: 600;
  color: #193B3C;
  margin-bottom: 0px;
}

.switches .switches-container {
  width: 20rem;
  position: relative;
  display: flex;
  padding: 0;
  position: relative;
  background-color: #193B3C;
  line-height: 3rem;
  border-radius: 3rem;
}

.withdraw-details-box .input-group span.input-group-text {
  background-color: #193B3C;
  color: #FFFFFF;
  border: 1px solid #193B3C;
  padding: 6px 20px;
}

.switches .switches-container input {
  visibility: hidden;
  position: absolute;
  top: 0;
}

.withdraw-details-box input {
  height: 52px;
}

input.form-control:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid #193B3C;
}

.switches .switches-container label {
  width: 50%;
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  color: #FFFFFF;
}

.switch-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  padding: 0.15rem;
  z-index: 3;
  transition: transform .5s cubic-bezier(.77, 0, .175, 1);
}

.switches .switch {
  border-radius: 3rem;
  background: #FFFFFF;
  height: 100%;
}

.switches .switch div {
  width: 100%;
  text-align: center;
  opacity: 0;
  display: block;
  color: #193B3C;
  font-size: 16px;
  font-weight: 700;
  transition: opacity .2s cubic-bezier(.77, 0, .175, 1) .125s;
  will-change: opacity;
  position: absolute;
  top: 0;
  left: 0;
}

.switches-container input:nth-of-type(1):checked~.switch-wrapper {
  transform: translateX(0%);
}

/* slide the switch box from left to right */
.switches-container input:nth-of-type(2):checked~.switch-wrapper {
  transform: translateX(100%);
}

/* toggle the switch box labels - first checkbox:checked - show first switch div */
.switches-container input:nth-of-type(1):checked~.switch-wrapper .switch div:nth-of-type(1) {
  opacity: 1;
}

/* toggle the switch box labels - second checkbox:checked - show second switch div */
.switches-container input:nth-of-type(2):checked~.switch-wrapper .switch div:nth-of-type(2) {
  opacity: 1;
}

/* Sorting Style */
.table-box table tr th.sorting {
  position: relative;
  padding-right: 26px;
}

.table-box table tr th.sorting:before {
  position: absolute;
  content: "▲";
  display: block;
  bottom: 50%;
  right: 10px;
  line-height: 9px;
  font-size: .7em;
  margin-bottom: 0.5px;
  opacity: 0.3;
}

.table-box table tr th.sorting:after {
  position: absolute;
  content: "▼";
  display: block;
  top: 50%;
  right: 10px;
  line-height: 9px;
  font-size: .7em;
  margin-top: 0.5px;
  opacity: 0.3;
}

.table-box table tr th.sorting.sorting_asc:before {
  opacity: 1;
}

.table-box table tr th.sorting.sorting_desc:after {
  opacity: 1;
}

.pagination-box .paginate_button.page-item a {
  color: #193B3C;
}

.pagination-box .paginate_button.page-item a.active {
  background-color: #193B3C;
  color: #FFFFFF;
}

.pagination-box .paginate_button.page-item a:focus {
  box-shadow: none;
}

.pagination-box .paginate_button.page-item span {
  color: #193B3C;
  cursor: pointer;
}

.pagination-box .paginate_button.page-item span.active {
  background-color: #193B3C;
  color: #FFFFFF;
}

.pagination-box .paginate_button.page-item span:focus {
  box-shadow: none;
}

.pagination {
  justify-content: center;
}

.withdraw-details {
  min-height: 280px;
  background-color: #e9ecef;
  padding: 30px;
  position: relative;
  overflow: hidden;
}

.withdraw-details:after {
  height: 251px;
  width: 255px;
  content: "";
  position: absolute;
  bottom: -20px;
  right: 0px;
  background-image: url('./assets/images/bg-logo-img.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.withdraw-details-box {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.withdraw-details-box label {
  font-size: 16px;
  font-weight: 600;
}

.withdraw-details ul {
  padding: 0px;
  z-index: 2;
  position: relative;
}

.withdraw-details ul li strong {
  font-size: 16px;
  font-weight: 600;
}

.withdraw-details ul li+li {
  padding-top: 5px;
}

.kyc-img {
  height: 150px;
  margin-top: -100px;
}

.qrCode-input div {
  display: block !important;
}

.qrCode-input input {
  border: 1px solid black;
  border-radius: 8px;
  width: 56px !important;
  height: 56px;
  font-size: 12px;
  color: #000;
  font-weight: 400;
  margin-right: 10px;
  margin-bottom: 10px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dropdown-item,
.form-switch input {
  cursor: pointer;
}

.loading-btn {
  background-color: #fad702 !important;
  border-radius: 8px !important;
  border-color: #fad702 !important;
  opacity: unset !important;
}

.footer {
  float: left;
  background-color: #FFFFFF;
  position: relative;
}

.footer .copyright {
  padding: 15px 30px;
}

.footer p,
.footer p a {
  font-size: 14px;
  font-weight: 400;
  color: #193B3C;
}

.footer p a:hover {
  opacity: 0.9;
}

input[type=number].no-spinner::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.header-main {
  position: fixed;
  top: 0%;
  width: 100%;
  z-index: 1000;
  opacity: 1;
}

button.navbar-toggler {
  border: 1px solid #193b3c !important;
  border-radius: 4px !important;
  padding: 0.25rem 0.15rem !important;
}

.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  text-align: center;
}

.sidenav div {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav div:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.energy-project-disable,
.energy-project-disable:hover,
.nature-project-disable,
.nature-project-disable:hover,
.greenh2-project-disable,
.greenh2-project-disable:hover,
.biochar-project-disable,
.biochar-project-disable:hover,
.greenDataCenter-project-disable,
.greenDataCenter-project-disable:hover {
  cursor: auto;
  background-color: #235152a3;
}

.energy-project-disable:hover .energy-text h4 span,
.nature-project-disable:hover .nature-text h4 span,
.greenh2-project-disable:hover .greenh2-text h4 span,
.biochar-project-disable:hover .biochar-text h4 span,
.greenDataCenter-project-disable:hover .greenDataCenter-text h4 span {
  display: none;
}

.energy-project-disable:hover .energy-text h4::after,
.nature-project-disable:hover .nature-text h4::after,
.greenh2-project-disable:hover .greenh2-text h4::after,
.biochar-project-disable:hover .biochar-text h4::after,
.greenDataCenter-project-disable:hover .greenDataCenter-text h4::after {
  content: "Coming Soon..";
}

.coming-soon {
  margin: auto;
  height: 70vh;
  display: flex;
  align-items: center;
}

.btn-success-color {
  color: white !important;
}

.btn-success-color:hover {
  color: #186300 !important;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav div {
    font-size: 18px;
  }
}

@media only screen and (max-width: 991px) {
  .dropdown-toggle span {
    font-size: 0px;
  }

  .dropdown-toggle::after {
    position: absolute;
    right: 5px;
  }

  .page-content .sidebar-content {
    display: none;
  }

  .page-content .content-wrapper {
    width: 100%;
  }

  .page-content .content-wrapper {
    margin-left: 0px;
  }

  .withdraw-details {
    min-height: 180px;
    padding: 30px 20px;
  }

  .nav-link.dropdown-toggle {
    padding: 10px 15px 10px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .nav-link.dropdown-toggle {
    padding: 10px 15px 10px 10px;
  }

  .icon-box {
    height: 80px;
    width: 80px;
  }

  .icon-box img {
    height: 40px;
    width: 40px;
  }

  .table-box table tr th,
  .table-box table tr td {
    font-size: 14px;
    padding: 10px 15px;
  }

  .page-title,
  .btn-group-style {
    flex-direction: column;
    gap: 20px
  }

  .pagination-section .entries-box {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }

  .switches {
    flex-direction: column;
    gap: 15px;
  }

  .switches h3 {
    font-size: 18px;
  }

  .withdraw-details:after {
    height: 151px;
    width: 150px;
  }
}