.kyc-head {
  font-size: 34px;
  font-weight: 500;
  height: fit-content;
}

.kyc {
  background: #ffffff;
  border-radius: 16px;
  filter: drop-shadow(1px 2px 30px rgba(0, 0, 0, 0.09));
}

.kyc-pi {
  font-weight: 500;
  font-size: 20px;
  color: #00a14b;
}

.kyc-ca {
  font-weight: 500;
  font-size: 20px;
  color: #656565;
}

.kyc-ud {
  background: #ffffff;
  border: 1px solid #00a14b;
  border-radius: 8px;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.kyc-plus {
  background: #00a14b;
  border-radius: 8px;
  color: #fff;
}

.kyc-minus {
  border: 1px solid #00a14b;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
}

.kyc-sb {
  height: 52px;
  background-color: #00a14b;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
}

.kyc-sb-2 {
  height: 52px;
  background-color: #fad702;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
}

.kyc-verification {
  margin-left: 2rem;
  margin-right: 2rem;
}

.kyc-verification .kyc-status {
  display: flex;
}

.kyc-verification .kyc-status {
  align-items: center;
}

.kyc-verification .kyc-status .pending-notification {
  margin-right: 5px;
}

.kyc-verification .kyc-heading {
  text-align: right;
}

.kyc-verification .kyc-status {
  float: right;
}

.kyc-verification .w-20 {
  width: 100%;
}

.kyc-verification .w-100.kyc {
  margin-top: 6rem;
}

.notstated-notification {
  background-color: #ff771b;
  border-radius: 100px;
  height: 18px;
  width: 18px;
}

.pending-notification {
  background-color: #e1be5a;
  border-radius: 100px;
  height: 18px;
  width: 18px;
}

.completed-notification {
  background-color: #00a14b;
  border-radius: 100px;
  height: 18px;
  width: 18px;
}

@media (min-width: 767.98px) and (max-width: 991.98px) {
  .kyc-verification .w-20 {
    width: 40%;
  }

  .kyc-verification .w-100.kyc {
    margin-top: 6rem;
  }

  .dashboard-tiles.kyc-verification {
    padding-top: 2.5rem !important;
  }
}

@media (max-width: 991px) {
  .kyc-verification .kyc-heading {
    font-size: 20px;
  }
}