.login-main {
  background: #fff;
}

.login-main form button a.nav-link,
.signup-section-2 form button a.nav-link {
  font-size: 20px;
}

.login-section-2 {
  margin-top: 10%;
}

.signup-target-image {
  margin-top: 20%;
  margin-left: 2%;
}

.login-flip-image {
  transform: scaleX(-1);
}

.login-image1 {
  position: absolute;
  height: 850px;
  left: -15%;
}

.signup-image1 {
  position: absolute;
  height: 850px;
  left: -32%;
}

.login-image2,
.signup-image2 {
  position: absolute;
  z-index: 1;
  top: 0%;
  height: 330px;
}

button.btn-close {
  position: absolute;
  left: 90%;
  top: 8%;
  opacity: 1;
}

.signup-section-2 {
  margin-top: 15%;
  margin-left: 30%;
}

.leaf-section {
  position: relative;
  left: 70%;
  top: -20%;
  z-index: 0;
}

.leaf-section img {
  width: 100%;
  height: 600px;
}

.signup-leaf-section {
  position: relative;
  left: 120%;
  top: -18%;
  width: fit-content;
}

.signup-leaf-section img {
  width: 50%;
  height: 600px;
}

/* for sm screen */
@media (min-width: 50px) and (max-width: 575px) {
  .login-image1 {
    height: 300px;
    left: -15%;
  }

  .login-image2 {
    height: 160px;
  }

  .signup-image1 {
    height: 300px;
    left: -15%;
  }

  .signup-image2 {
    height: 160px;
  }

  .login-section-2 {
    position: relative;
    z-index: 1;
    background-color: #fff;
    top: 85px;
    border-radius: 6px;
    padding: 10%;
    height: 560px;
  }

  .login-section-2 h1 {
    font-size: 21px;
  }

  .login-section-2 h1+p {
    font-size: 14px;
    margin-bottom: 10%;
  }

  .login-section-2 input.form-control {
    padding: 1rem;
  }

  .login-main {
    background: #f6f6f6;
  }

  .signup-section-2 {
    margin-top: 50%;
    margin-left: 8%;
  }

  .signup-section-2 h1 {
    font-size: 21px;
  }

  .signup-section-2 p {
    font-size: 14px;
  }

  .leaf-section {
    left: 16%;
    top: -16%;
    z-index: -1;
  }

  .leaf-section img {
    width: 50%;
    height: 200px;
  }

  .signup-leaf-section {
    left: 36%;
    top: -15%;
  }

  .signup-leaf-section img {
    width: 40%;
    height: 300px;
  }
}

/* for sm screen */
@media (min-width: 576px) and (max-width: 767px) {
  .login-image1 {
    height: 450px;
    left: -15%;
  }

  .login-image2 {
    height: 200px;
  }

  .signup-image1 {
    height: 450px;
    left: -15%;
  }

  .signup-image2 {
    height: 200px;
  }

  .login-section-2 {
    position: relative;
    z-index: 1;
    background-color: #fff;
    top: 100px;
    border-radius: 6px;
    padding: 10%;
    height: 630px;
  }

  .login-section-2 h1 {
    font-size: 21px;
  }

  .login-section-2 h1+p {
    font-size: 14px;
    margin-bottom: 10%;
  }

  .login-section-2 input.form-control {
    padding: 1rem;
  }

  .login-main {
    background: #f6f6f6;
  }

  .signup-section-2 {
    margin-top: 40%;
    margin-left: 8%;
  }

  .signup-section-2 h1 {
    font-size: 21px;
  }

  .signup-section-2 p {
    font-size: 14px;
  }

  .leaf-section {
    left: 16%;
    top: -18%;
    z-index: -1;
  }

  .leaf-section img {
    width: 40%;
    height: 200px;
  }

  .signup-leaf-section {
    left: 64%;
    top: -18%;
  }

  .signup-leaf-section img {
    width: 100%;
    height: 300px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .login-image1 {
    height: 350px;
    left: -15%;
  }

  .login-image2 {
    height: 180px;
  }

  .signup-image1 {
    height: 550px;
    left: -15%;
  }

  .signup-image2 {
    height: 220px;
  }

  .signup-target-image {
    margin-top: 32%;
    margin-left: 2%;
    height: 120px !important;
    width: 120px !important;
  }

  .login-section-2 {
    position: relative;
    z-index: 1;
    background-color: #fff;
    top: 0px;
    padding: 0%;
  }

  .login-section-2 h1 {
    font-size: 21px;
  }

  .login-section-2 h1+p {
    font-size: 14px;
    margin-bottom: 10%;
  }

  .login-main {
    background: #ffffff;
  }

  .signup-section-2 {
    margin-top: 32%;
    margin-left: 20%;
  }

  .signup-section-2 h1 {
    font-size: 21px;
  }

  .signup-section-2 p {
    font-size: 14px;
  }

  .leaf-section {
    left: 16%;
    top: -18%;
    z-index: -1;
  }

  .leaf-section img {
    width: 50%;
    height: 200px;
  }

  .signup-leaf-section {
    left: 20%;
    top: -30%;
  }

  .signup-leaf-section img {
    width: 80%;
    height: 400px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .login-image1 {
    height: 450px;
    left: -15%;
  }

  .login-image2 {
    height: 220px;
  }

  .signup-image1 {
    height: 650px;
    left: -15%;
  }

  .signup-image2 {
    height: 260px;
  }

  .signup-target-image {
    margin-top: 28%;
    margin-left: 2%;
    height: 200px !important;
    width: 200px !important;
  }

  .login-section-2 {
    position: relative;
    z-index: 1;
    background-color: #fff;
    top: 0px;
    padding: 0%;
  }

  .login-section-2 h1 {
    font-size: 21px;
  }

  .login-section-2 h1+p {
    font-size: 14px;
    margin-bottom: 10%;
  }

  .login-main {
    background: #ffffff;
  }

  .signup-section-2 {
    margin-top: 30%;
  }

  .signup-section-2 h1 {
    font-size: 21px;
  }

  .signup-section-2 p {
    font-size: 14px;
  }

  .leaf-section {
    left: 46%;
    top: -16%;
    z-index: -1;
  }

  .leaf-section img {
    width: 80%;
    height: 400px;
  }

  .signup-leaf-section {
    left: 100%;
    top: -30%;
  }

  .signup-leaf-section img {
    width: 80%;
    height: 400px;
  }
}

/* for sm screen */
@media (min-width: 1200px) and (max-width: 1399px) {
  .login-image1 {
    height: 600px;
    left: -15%;
  }

  .login-image2 {
    height: 280px;
  }

  .login-section-2 {
    position: relative;
    z-index: 1;
    background-color: #fff;
    top: 0px;
    padding: 0%;
  }

  .login-section-2 h1 {
    font-size: 21px;
  }

  .login-section-2 h1+p {
    font-size: 14px;
    margin-bottom: 10%;
  }

  .login-main {
    background: #ffffff;
  }

  .signup-target-image {
    margin-top: 32%;
    margin-left: 2%;
    height: 300px !important;
    width: 300px !important;
  }

  .signup-section-2 {
    margin-top: 36%;
  }

  .signup-section-2 h1 {
    font-size: 21px;
  }

  .signup-section-2 p {
    font-size: 14px;
  }

  .leaf-section {
    left: 60%;
    top: -16%;
    z-index: -1;
  }

  .leaf-section img {
    width: 80%;
    height: 400px;
  }

  .signup-leaf-section {
    left: 72%;
    top: -30%;
  }

  .signup-leaf-section img {
    width: 80%;
    height: 400px;
  }
}

/* for sm screen */
@media (min-width: 1400px) and (max-width: 1600px) {
  .login-image1 {
    height: 600px;
    left: -15%;
  }

  .login-image2 {
    height: 280px;
  }

  .login-section-2 {
    position: relative;
    z-index: 1;
    background-color: #fff;
    top: 0px;
    padding: 0%;
  }

  .login-section-2 h1 {
    font-size: 48px;
  }

  .login-section-2 h1+p {
    font-size: 14px;
    margin-bottom: 10%;
  }

  .signup-target-image {
    margin-top: 25%;
    margin-left: 2%;
    height: 300px !important;
    width: 300px !important;
  }

  .login-main {
    background: #ffffff;
  }

  .signup-section-2 h1 {
    font-size: 40px;
  }

  .signup-section-2 p {
    font-size: 14px;
  }

  .leaf-section {
    left: 60%;
    top: -16%;
    z-index: -1;
  }

  .leaf-section img {
    width: 80%;
    height: 400px;
  }

  .signup-leaf-section {
    left: 72%;
    top: -30%;
  }

  .signup-leaf-section img {
    width: 70%;
    height: 400px;
  }
}