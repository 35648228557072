/* Your styles goes here. */

.sunset-input-active {
  border: none !important;
  box-shadow: 0 0 16px #dddddd !important;
}

.input-last-icons {
  top: -40px;
  left: -20px;
}

.my-token-tabs .nav button.active {
  color: #00a14b !important;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.carousel-indicators button {
  background-color: #d8d8d8 !important;
  height: 8px !important;
  width: 8px !important;
  border-radius: 50% !important;
}

.carousel-indicators button.active {
  background-color: #00db16 !important;
}

i:hover {
  cursor: pointer;
}

.news-headlines {
  margin: -160px 15px 0% 1%;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background: rgba(0, 0, 0, 0.3);
}

.news-headlines div.card-body {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background: rgba(0, 0, 0, 0.3);
}

a {
  text-decoration: none;
  color: initial;
}

a:hover {
  text-decoration: none;
  color: initial;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.notification {
  padding: 10px 20px;
  color: white;
  border-radius: 0px 0px 10px 10px;
  margin-top: -40px;
}

.page-item {
  margin-left: 20px !important;
}

.input-icon {
  background: initial;
  border-right: none;
  border: 1px solid #e3e6e9;
}

.input-icon+input {
  border-left: none;
}

.info-icon {
  height: 15px;
  margin-left: 2%;
}

.otp-all-input {
  height: 50px;
  width: 50px;
  margin: 0px 15px 20px 0px;
  border-radius: 4px;
  background-color: #f3f5f6;
  border: none;
  /* padding-left: 30%; */
  font-size: 16px !important;
  color: black;
}

.active {
  background: #ffffff !important;
  color: #fff !important;
}

.header-main .nav-item .nav-link {
  color: var(--tertiary);
  font-weight: 500;
}

.header-main .nav-item .nav-link.active {
  border-bottom: 2px solid var(--secondary) !important;
  color: var(--secondary) !important;
}

.modal-size {
  width: 50%;
  height: 50%;
}

.shadow-partner {
  box-shadow: -2px 6px 36px 5px rgba(0, 0, 0, 0.5);
}

.button-green {
  background-color: var(--primary);
  color: var(--white);
}

.button-brown {
  background-color: var(--secondary);
}

.modal-90w {
  max-width: 90% !important;
  height: 90% !important;
}

#set-dd {
  color: black;
  border: none;
  box-shadow: none;
  background-color: #f3f5f6;
  border-radius: 5px;
  width: 100%;
  height: 40px;
}

.dropdown-img {
  width: 30px;
}

.btn-warning img {
  filter: brightness(0) invert(1);
}

.bg-tertiary {
  background: var(--tertiary);
}

.signup-main .card-body {
  z-index: 1;
}

/* Your styles goes here. */

.sunset-input-active {
  border: none !important;
  box-shadow: 0 0 16px #dddddd !important;
}

.input-last-icons {
  top: -40px;
  left: -20px;
}

.my-token-tabs .nav button.active {
  color: #00a14b !important;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.carousel-indicators button {
  background-color: #d8d8d8 !important;
  height: 8px !important;
  width: 8px !important;
  border-radius: 50% !important;
}

.carousel-indicators button.active {
  background-color: #00db16 !important;
}

i:hover {
  cursor: pointer;
}

.news-headlines {
  margin: -160px 15px 0% 1%;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background: rgba(0, 0, 0, 0.3);
}

.news-headlines div.card-body {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background: rgba(0, 0, 0, 0.3);
}

a {
  text-decoration: none;
  color: initial;
}

a:hover {
  text-decoration: none;
  color: initial;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.notification {
  padding: 10px 20px;
  color: white;
  border-radius: 0px 0px 10px 10px;
  margin-top: -40px;
}

.page-item {
  margin-left: 20px !important;
}

.input-icon {
  background: initial;
  border-right: none;
  border: 1px solid #e3e6e9;
}

.input-icon+input {
  border-left: none;
}

.info-icon {
  height: 15px;
  margin-left: 2%;
}

.otp-all-input {
  height: 50px;
  width: 50px;
  margin: 0px 15px 20px 0px;
  border-radius: 4px;
  background-color: #f3f5f6;
  border: none;
  /* padding-left: 30%; */
  font-size: 16px !important;
  color: black;
}

.header-main .nav-item .nav-link {
  color: var(--tertiary);
  font-weight: 500;
}

.header-main .nav-item .nav-link.active {
  border-bottom: 2px solid var(--secondary) !important;
  color: var(--secondary) !important;
}

.modal-size {
  width: 50%;
  height: 50%;
}

.shadow-partner {
  box-shadow: -2px 6px 36px 5px rgba(0, 0, 0, 0.5);
}

.button-green {
  background-color: var(--primary);
  color: var(--white);
}

.button-brown {
  background-color: var(--secondary);
}

.modal-90w {
  max-width: 90% !important;
  height: 90% !important;
}

#set-dd {
  color: black;
  border: none;
  box-shadow: none;
  background-color: #f3f5f6;
  border-radius: 5px;
  width: 100%;
  height: 40px;
}

.dropdown-img {
  width: 30px;
}

.btn-warning img {
  filter: brightness(0) invert(1);
}

.bg-tertiary {
  background: var(--tertiary);
}

.signup-main .card-body {
  z-index: 1;
}